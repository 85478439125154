
























import { Component, Vue, Mixins } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    ModalBase,
  },
})
export default class ModalDefaultExitCondition extends Mixins(ModalBaseMethod) {
  private selectedSubject = ''

  private itemOptionDatas = []

  private itemSelectedData = 0

  private itemLimit = 0

  private id = 0

  public async setDefaultExitCondition(id: number, subject: string, restrictionId: number, threshold: number) {
    this.itemSelectedData = restrictionId
    this.itemLimit = threshold
    this.selectedSubject = subject
    this.id = id
  }

  get setUnit(): string {
    const itemOption: any = this.itemOptionDatas.find((item: { value: number; unit: string }) => {
      return this.itemSelectedData === item.value
    })
    return itemOption ? itemOption.unit : ''
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted = this.itemLimit && this.itemLimit > 0

    return permitted ? 'blue' : 'pointer-events-none'
  }

  private mounted() {
    this.loadDatas()
  }

  private async loadDatas() {
    await Vue.prototype.$http.httpWithToken.get('/restrictions').then((res: any) => {
      this.itemOptionDatas = res.data.restrictions.map((restriction: { id: number; name: string; unit: string }) => {
        return {
          value: restriction.id,
          text: restriction.name,
          unit: restriction.unit,
        }
      })
    })
  }
  private async update(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .put(`/branch_end_conditions/${this.id}`, { restrictionId: this.itemSelectedData, threshold: this.itemLimit })
      .then(() => {
        this.hide()
        this.$emit('loadDatas')
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }
}
